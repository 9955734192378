import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <h1>
          Edit About component or pages/contact.jsx to include your information.
        </h1>
      </div>
    );
  }
}

export default Contact;
