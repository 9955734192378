import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import Navbar from "../components/Navbar/Navbar";

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Contact | ${config.siteTitle}`} />
        <Navbar active="contact"/>
        <h1>awdlwaw</h1>
      </Layout>
    );
  }
}

export default ContactPage;
